import React, { FC, useState } from "react";
import { FormSelect } from "../../components/FormFields";
import { PaymentInterface, PaymentMethodEnum } from "../../interfaces";
import P2CPaymentForm from "./P2CPaymentForm";
import LoadingIcon from "../../components/LoadingIcon";
import classNames from "classnames";

const PAYMENT_METHODS = [
  {
    paymentMethodID: PaymentMethodEnum.PAGO_MOVIL_P2C,
    paymentMethodName: "Pago Móvil P2C",
    currencyID: 1,
  },
];

interface PaymentFormProps {
  remaining: number;
  onPay: (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => Promise<{
    error: boolean;
    message: string;
  }>;
}

const PaymentForm: FC<PaymentFormProps> = ({ onPay, remaining }) => {
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS[0]);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <h2 className="text-lg font-semibold text-gray-700">Reportar pago</h2>

        {loading && <LoadingIcon size="1.5rem" />}
      </div>

      <div
        className={classNames(
          "flex flex-col flex-1 mb-4",
          PAYMENT_METHODS.length <= 1 && "hidden"
        )}
      >
        <FormSelect
          label="Método de pago"
          name="paymentMethod"
          options={PAYMENT_METHODS}
          optionString={(p) => p.paymentMethodName}
          selected={paymentMethod}
          onSelectOption={setPaymentMethod}
        />
      </div>

      <P2CPaymentForm
        onPay={async (payment, avoidVerifications) => {
          setLoading(true);
          const response = await onPay(payment, avoidVerifications);
          setLoading(false);
          return response;
        }}
        remaining={remaining}
      />
    </div>
  );
};

export default PaymentForm;
