import React, { useCallback, useEffect, useMemo, useState } from "react";
import { validate as isValidUUID } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/LogoTEALCA.svg";
import {
  DocumentType,
  DocumentInterface,
  DocumentStatus,
  PaymentInterface,
  PaymentMode,
  ShipmentInterface,
  ShipmentService,
  TaxInterface,
  UserInterface,
  PaymentStatusEnum,
  ShipmentStatus,
  PaymentMethodEnum,
} from "../../interfaces";
import {
  alertService,
  consultBC,
  CreateOrUpdateSession,
  getDocument,
  getShipment,
  getShipmentRates,
  loaderService,
  mercantilC2P,
  mercantilChange,
  mercantilMobilePaymentSearch,
  mercantilTransferSearch,
  payMerchant,
  ReintegrationRequest,
  saveDocument,
  savePayment,
  sendB2PBC,
  sendC2PBC,
  updateDocumentStatus,
  updateShipmentStatus,
  userLogin,
} from "../../services";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import classNames from "classnames";
import LoadingIcon from "../../components/LoadingIcon";
import AccountBillToCard from "./AccountBillToCard";
import ShipmentTable from "../../components/Shipment/ShipmentTable";
import DocumentTotal from "./DocumentTotal";
import ShipmentPayments from "./ShipmentPayments";
import PaymentForm from "./PaymentForm";
import { currencyExchange, useCurrencyExchanges } from "../../utils";
import moment from "moment";
import { setUser } from "../../store/slices";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const ShipmentPay = () => {
  const navigate = useNavigate();
  const { shipmentID } = useParams();
  const dispatch = useAppDispatch();
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const user = useAppSelector((state) => state.user.user);
  const exchanges = useCurrencyExchanges();

  const url = new URL(window.location.href);
  const hideLogo = url.searchParams.get("hide-logo");

  const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState<ShipmentInterface[]>([]);
  const [document, setDocument] = useState<DocumentInterface>();

  const accountBillTo = useMemo(() => {
    if (!shipments[0]) return undefined;

    const shipment = shipments[0];
    if (document) {
      return document.accountOwner;
    } else if (
      !shipment.paymentMode ||
      shipment.paymentMode in
        [PaymentMode.CONTADO, PaymentMode.BOXOFFICE_CREDIT]
    ) {
      return shipment.shipper;
    } else {
      return shipment.consignee;
    }
  }, [shipments, document]);

  const subTotal = useMemo(() => {
    return +shipments
      .reduce((acc, shipment) => {
        return (
          acc +
          (shipment?.items.reduce((acc, item) => acc + item.rate.value, 0) ?? 0)
        );
      }, 0)
      .toFixed(2);
  }, [shipments]);

  const ipostel = useMemo(() => {
    return (
      document?.ipostel ??
      document?.taxes.find((t) => t.name === "Ipostel")?.value ??
      shipments.reduce((acc, shipment) => {
        return (
          acc + shipment.items.reduce((acc, item) => acc + item.rate.ipostel, 0)
        );
      }, 0)
    );
  }, [shipments, document?.ipostel, document?.taxes]);

  const iva = useMemo(() => {
    return shipments.reduce((acc, shipment) => {
      return acc + shipment.items.reduce((acc, item) => acc + item.rate.iva, 0);
    }, 0);
  }, [shipments]);

  const taxes = useMemo(() => {
    const taxes: TaxInterface[] = [
      {
        name: "IVA",
        value: iva,
        type: "fixed",
      },
      {
        name: "Ipostel",
        value: ipostel,
        type: "fixed",
      },
    ];

    if (!!document?.igtfAmount) {
      taxes.push({
        name: "IGTF",
        value: document.igtfAmount,
        type: "fixed",
      });
    }

    return taxes;
  }, [iva, ipostel, document?.igtfAmount]);

  const total = useMemo(() => {
    return +(
      subTotal +
      taxes?.reduce((acc, tax) => {
        if (tax.type === "percentage") {
          return acc + subTotal * (tax.value / 100);
        }
        return acc + tax.value;
      }, 0)
    ).toFixed(2);
  }, [subTotal, taxes]);

  const remaining = useMemo(() => {
    const payments = document?.payments ?? [];
    const result =
      document?.balanceAmount !== undefined
        ? document?.balanceAmount -
          payments
            .filter((p) => p.status === PaymentStatusEnum.PENDING)
            .reduce((acc, payment) => acc + payment.amount, 0)
        : +(
            total -
            payments
              .filter(
                (p) =>
                  p.status !== PaymentStatusEnum.CANCELED &&
                  p.status !== PaymentStatusEnum.REJECT
              )
              .reduce((acc, payment) => acc + payment.amount, 0)
          ).toFixed(2);
    return result;
  }, [total, document]);

  const getShipmentData = useCallback(
    async (shipmentID: string) => {
      const shipment = await getShipment(shipmentID);

      if (!shipment) {
        navigate(`/*/404`);
        return;
      }

      if (shipment.document) {
        setShipments([shipment]);

        const document = await getDocument(shipment.document.documentId);

        if (document) {
          setDocument(document);

          const shipments: ShipmentInterface[] = [];
          for (const shipment of document.shipments) {
            const updatedShipment = await getShipment(shipment.id!);
            if (updatedShipment) {
              shipments.push(updatedShipment);
            }
          }

          setShipments(shipments);

          return document;
        }

        return;
      }

      const rates = await getShipmentRates(
        shipment.service ?? ShipmentService.STANDARD,
        shipment.paymentMode ?? PaymentMode.COD,
        shipment.deliveryType,
        shipment.buSource.code,
        businessUnits.find((bu) => bu.code === shipment.buConsignee?.code!)!
          .code ?? "",
        shipment.shipper.id,
        shipment.consignee.id,
        shipment.accountBillTo?.id,
        businessUnits.find((bu) => bu.code === shipment.buSource?.code!)!
          .location,
        shipment.consigneeAddress,
        shipment.items,
        shipment.pieces,
        shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
        new Date().toISOString(),
        applicationID,
      );

      if (rates.didError || !rates.model) {
        alertService.warn(
          `Hubo un error al obtener la tarifa de la guía ${shipment.shipmentNumber}.`
        );
        return;
      }

      setShipments([
        {
          ...shipment,
          items: rates.model.items,
          total: rates.model.items.reduce((acc, i) => acc + i.rate.value, 0),
        },
      ]);
    },
    [applicationID, navigate, businessUnits]
  );

  const updateDocument = async (document?: DocumentInterface) => {
    if (!document?.documentID) {
      return;
    }

    loaderService.start();

    const documentResponse = await getDocument(document.documentID);

    if (!documentResponse) {
      loaderService.stop();
      return undefined;
    }

    let updatedDocument = {
      ...document!,
      documentNumber: documentResponse.documentNumber,
      payments: documentResponse.payments,
      total: documentResponse.total,
      status: documentResponse.status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    loaderService.stop();
    setDocument(updatedDocument);

    return updatedDocument;
  };

  const onSave = async (): Promise<DocumentInterface | undefined> => {
    // If there are a document, don't save it again
    if (!!document?.documentID) {
      return document;
    }

    const status = total < 0.01 ? DocumentStatus.PAID : DocumentStatus.PENDING;
    const shipment = shipments[0];
    const paymentMode = shipment.paymentMode ?? PaymentMode.COD;

    // Save document
    const documentData: DocumentInterface = {
      documentID: "",
      accountOwner: accountBillTo,
      taxes,
      paymentMode: paymentMode,
      subTotal,
      total,
      status,
      shipments: shipments,
      payments: [],
      printed: false,
      hasRetention: false, // document.payments.some((p) => p.isRetention)
      igtfAmount: 0,
      balanceAmount: total,
      documentType: DocumentType.ORDER,
    };

    if (
      paymentMode === PaymentMode.COD ||
      paymentMode === PaymentMode.CREDIT ||
      paymentMode === PaymentMode.BOXOFFICE_CREDIT ||
      remaining < 0.01
    ) {
      // Update shipments
      const updatedShipments: ShipmentInterface[] = [];

      for (const shipment of shipments) {
        const response = await updateShipmentStatus(
          ShipmentStatus.ACTIVE,
          shipment.id,
          shipment.shipmentNumber,
          user ?? undefined
        );

        if (!response || response.didError || !response.model) {
          alertService.error(
            `Error al actualizar la guía`,
            response?.errorMessage
          );
          return;
        } else {
          updatedShipments.push({
            ...shipment,
            status: ShipmentStatus.ACTIVE,
          });
        }
      }

      documentData.shipments = updatedShipments;
    }

    const businessUnitCode =
      paymentMode in
      [PaymentMode.COD, PaymentMode.CREDIT, PaymentMode.DEST_BOXOFFICE_CREDIT]
        ? shipment.buConsignee?.code
        : shipment.buSource?.code;

    const response = await saveDocument(
      documentData,
      businessUnitCode,
      user ?? undefined,
      accountBillTo,
      "",
      applicationID
    );
    if (response.didError || !response.model?.id_solicitud) {
      alertService.error(`Error al guardar la orden`, response.errorMessage);
      loaderService.stop();
      return;
    }

    const newDocument = {
      ...documentData,
      documentID: response.model.id_solicitud,
    };

    const documentType =
      status === DocumentStatus.PAID ||
      paymentMode === PaymentMode.BOXOFFICE_CREDIT
        ? DocumentType.INVOICE
        : DocumentType.ORDER;

    if (documentType === DocumentType.INVOICE) {
      const response = await updateDocumentStatus(
        newDocument.documentID,
        status,
        documentType,
        user ?? undefined,
        status === DocumentStatus.PAID ? businessUnitCode : undefined, // set BU invoicer,
        DocumentType.ORDER
      );

      if (!!response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        newDocument.status = status;
        newDocument.documentType = documentType;
      }
    }

    loaderService.stop();

    return await getShipmentData(shipment.id!);
  };

  const onPay = async (
    payment: PaymentInterface,
    avoidVerifications?: boolean
  ) => {
    const paymentVerified = avoidVerifications;
    let result = {
      error: false,
      message: "",
    };

    loaderService.start();
    let savedDocument;

    if (document?.documentID) {
      savedDocument = document;
    } else {
      savedDocument = await onSave();
      if (!savedDocument) {
        loaderService.stop();
        return {
          error: true,
          message: "Error al crear el documento",
        };
      }
    }

    payment.documentID = savedDocument.documentID;
    payment.documentNumber = savedDocument.documentNumber ?? undefined;

    if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: Math.abs(payment.paymentMethod.paymentMethodID),
      };
    } else if (
      paymentVerified &&
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT
    ) {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
      };
    }

    // Merchant payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.MERCHANT ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_P2C ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO ||
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO
    ) {
      let ci = payment.clientIdentifier!;
      if (isNaN(parseInt(ci[0]))) {
        ci = ci.slice(1);
      }

      payment.paymentMethod.paymentMethodID =
        PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
      payment.paymentMethod.bankID = 27;
      payment.paymentMethod.currencyID = 1;

      const res = await payMerchant(
        currencyExchange(payment.amount, exchanges, "BS"),
        ci
      );

      if (!res || res.codRespuesta !== "00") {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.mensajeRespuesta ?? "No hubo respuesta de Merchant",
        };
      } else {
        if (res.tipoProducto !== "P2C") {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO;
          payment.paymentMethod.bankID = 27;
          payment.reference = `${res?.numSeq ?? ""}:${
            res?.terminalVirtual ?? ""
          }:${res?.numeroAutorizacion}`;
          payment.status = PaymentStatusEnum.PENDING;
        } else {
          payment.paymentMethod.paymentMethodID =
            PaymentMethodEnum.PAGO_MERCHANT_P2C;
          payment.paymentMethod.bankID = 4;
          payment.reference = res?.numeroReferencia ?? "";
        }
      }
    }

    // P2C payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.reference!,
        "PM",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // P2C payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_P2C &&
      payment.destBankID === 4
    ) {
      const res = await mercantilMobilePaymentSearch(
        payment.phone!,
        moment(payment.paymentDate).toDate(),
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError || res.model?.transaction_list?.length === 0) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else if (
        res.model?.transaction_list &&
        res.model?.transaction_list?.length > 0
      ) {
        payment.reference = res?.model?.transaction_list[0].payment_reference;
      }
    }

    // TRF payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 6
    ) {
      const res = await consultBC(
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        "",
        payment.reference!,
        "TRF",
        ""
      );

      if (!res?.success) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: !!res?.message
            ? res.message.code + " " + res.message.message
            : "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // TRF payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
      payment.destBankID === 4
    ) {
      const res = await mercantilTransferSearch(
        payment.clientIdentifier!,
        moment(payment.paymentDate).toDate(),
        payment.bank!,
        payment.reference!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.attempts ?? 1
      );

      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.errorList && res.model?.errorList.length > 0) {
          errorMessage = res.model?.errorList
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      }
      if (
        res.model?.transferSearchList &&
        res.model?.transferSearchList?.length > 0
      ) {
        if (res.model?.transferSearchList[0].trxStatus === "R") {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "La transferencia fue rechazada por el banco",
          };
        } else if (
          res.model?.transferSearchList[0].previouslySearched === "S"
        ) {
          payment.status = PaymentStatusEnum.REJECT;
          result = {
            error: true,
            message: "El pago ya fue verificado previamente",
          };
        }
      }
    }

    // C2P payment - Bancaribe
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 6
    ) {
      const res = await sendC2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        currencyExchange(payment.amount, exchanges, "BS"),
        payment.phone!,
        payment.otp
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      }
      payment.reference = res?.secuencial.toString();
    }

    // C2P payment - Mercantil
    else if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_C2P &&
      payment.destBankID === 4
    ) {
      const res = await mercantilC2P(
        payment.phone!,
        payment.clientIdentifier!,
        payment.otp!,
        savedDocument?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // POS payment
    else if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.PUNTO_DE_VENTA
    ) {
      payment.reference = `${payment.affiliateNumber}:${payment.batchNumber}`;
    }

    // B2P change payment - Bancaribe
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 6
    ) {
      const res = await sendB2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        payment.amount,
        payment.phone!,
        document ? `Asociado a orden #${document.documentID}` : "Pago 1 B2P",
        "Factura"
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // B2P change payment - Mercantil
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 4
    ) {
      const res = await mercantilChange(
        payment.phone!,
        payment.clientIdentifier!,
        document?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // Reintegration
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.REINTEGRO &&
      payment.status === PaymentStatusEnum.PENDING
    ) {
      const res = await ReintegrationRequest(
        user?.userLogin!,
        businessUnits.find((bu) => bu.code === document?.buCodeInvoicer)!,
        document!,
        payment.amount,
        document?.accountOwner!
      );

      payment.paymentMethod.bankAccountID = undefined;

      if (res.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,

          message: res.errorMessage,
        };
      }
    }

    // verifies that the payment was not verified before
    let verified = false;
    if (
      payment.status !== PaymentStatusEnum.REJECT &&
      payment.paymentMethod.paymentMethodID !== PaymentMethodEnum.EFECTIVO &&
      !paymentVerified
    )
      document?.payments.forEach((p) => {
        if (
          p.reference === payment.reference &&
          p.reference !== "" &&
          (p.status === PaymentStatusEnum.APPROVE ||
            p.status === PaymentStatusEnum.PENDING)
        ) {
          verified = true;
        }
      });

    if (verified) {
      payment.status = PaymentStatusEnum.REJECT;
      result = {
        error: true,
        message: "El pago ya fue verificado previamente",
      };
    }

    // Convert amount to negative if change
    if (
      [
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
        PaymentMethodEnum.VUELTO_EFECTIVO,
        PaymentMethodEnum.REINTEGRO,
      ].includes(payment.paymentMethod.paymentMethodID)
    ) {
      payment.amount = -payment.amount;
      payment.paymentAmount = -(payment.paymentAmount ?? 0);
    }

    // Change currency
    let currencyId = payment.paymentMethod.currencyID;

    const response = await savePayment(
      payment,
      savedDocument!,
      savedDocument?.buCodeInvoicer,
      user ?? undefined,
      currencyId
    );
    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error("Error al guardar el pago", response.errorMessage);
      return {
        error: true,
        message: "Error al guardar el pago",
      };
    }
    if (payment.status === PaymentStatusEnum.PENDING) {
      alertService.success(
        "El pago se registró exitosamente",
        "Deberá ser conciliado por Casa Matriz",
        { autoCloseDelay: 15000 }
      );
    } else if (payment.status === PaymentStatusEnum.APPROVE) {
      alertService.success("El pago se registró exitosamente");
    }

    // Update document
    await updateDocument(savedDocument);

    loaderService.stop();

    return result;
  };

  useEffect(() => {
    if (!shipmentID || !isValidUUID(shipmentID)) {
      navigate(`/*/404`);
      return;
    }

    setLoading(true);
    getShipmentData(shipmentID).finally(() => setLoading(false));
  }, [shipmentID, navigate, getShipmentData]);

  // Login
  useEffect(() => {
    const Login = async () => {
      loaderService.start();
      const userLogged = await userLogin(
        process.env.REACT_APP_SHIPMENT_APP_USER!,
        process.env.REACT_APP_SHIPMENT_APP_PASS!,
        "120"
      );
      const user = userLogged as UserInterface;
      dispatch(setUser(user));
      const token = user?.token;
      localStorage.setItem("Token", token);

      const session = await CreateOrUpdateSession(user.userID, "120", "NN");
      if (session.didError) {
        console.log(session.errorMessage);
      }
      loaderService.stop();
    };

    Login();
  }, []);

  useEffect(() => {
    if (document?.status === DocumentStatus.PAID) {
      navigate(`/document/${document.documentID}`);
    }
  }, [document, navigate]);

  return (
    <div className="flex flex-col relative">
      {/* Back buttom */}
      <div onClick={() => navigate(-1)} className="absolute top-0 left-0 mt-4 ml-4 cursor-pointer flex items-center">
        <ChevronLeftIcon
          className="h-6 w-6 text-gray-700"
        />
        <span className="text-gray-700 font-semibold">Volver</span>
      </div>

      <div className="flex flex-1 items-center justify-center">
        <img src={logo} alt="Logo TEALCA" className={classNames("h-32 mt-8", hideLogo !== null && "hidden")} />
      </div>

      <div className="p-4 pb-8 shadow-lg rounded-lg mx-2 md:mx-5 mb-8 mt-8 flex flex-col lg:flex-row gap-10 border border-gray-300 bg-white overflow-hidden">
        <div
          className={classNames(
            "flex items-center justify-center flex-1",
            !loading && "hidden"
          )}
        >
          <div className="flex flex-col items-center justify-center flex-1 p-8 rounded-2xl my-20 min-w-20 min-h-20 max-w-64 max-h-64">
            <LoadingIcon size="5rem" />
            <p className="mt-2 text-xl text-center font-light text-gray-900">
              Cargando guía...
            </p>
          </div>
        </div>

        <div
          className={classNames(
            "flex flex-1 flex-col gap-12 max-w-full",
            loading && "hidden"
          )}
        >
          {accountBillTo && <AccountBillToCard account={accountBillTo} />}

          <div className="flex-1">
            {(document || shipments) && <ShipmentTable shipments={shipments} />}
          </div>

          <div>
            <ShipmentPayments document={document} />
          </div>

          <div className="flex flex-1 gap-12 flex-col lg:flex-row">
            <div className="flex flex-[1.25]">
              {shipments[0] && (
                <DocumentTotal shipment={shipments[0]} document={document} />
              )}
            </div>

            <div className="flex flex-[2] flex-col gap-4">
              <PaymentForm onPay={onPay} remaining={remaining} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentPay;
